<template>
    <div class="p-relative">
        <!-- Тулбар -->
        <div class="top-btns-bar">
            <div class="transparent-buttons">

                <v-btn small tile text @click="ImportList" :disabled="loading">
                    <v-icon left>far fa-paper-plane</v-icon>
                    {{ $t('Импорт_списка') }}
                </v-btn>

            </div>
        </div>

        <!--Форма-->
        <v-form class="wrapperForm" ref="form" lazy-validation>
            <v-row dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">
                    <v-card class="extend-filter-common-box" flat>
                        <v-card-text>
                            <div :class="{'white-panel-items' : true, 'wpi-in-grids' : true, 'mb-0' : (!filterPanelState) }">
                                <div class="wp-row">
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{$t("Территориальная_единица")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">

                                            <v-select
                                                v-model="region"
                                                :items="regions"
                                                item-text="name"
                                                item-value="id"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                class="autocomplete-chip ac-200 mt-1 mb-1 mr-1"
                                                :placeholder="$t('Территориальная_единица')"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                                clearable
                                                v-tooltip.top-center='region && regions.length > 0 ? regions.find(r => r.id === region).name : ""'
                                                :readonly="regions.length == 1"
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-text-field
                                                                :placeholder="$t('Поиск')"
                                                                v-model="regionsSearch"
                                                                class="mb-2 autocomplete-search"
                                                                hide-details
                                                                dense
                                                                clearable
                                                                autofocus
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-select>

                                            <v-select
                                                v-if="region"
                                                v-model="electionPart"
                                                :items="electionParts"
                                                item-value="id"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                class="autocomplete-chip ac-200 ma-1"
                                                :placeholder="$t('Избирательный_участок')"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                                clearable
                                                v-tooltip.top-center='getElectionPartName(electionParts.find(r => r.id === electionPart))'
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-text-field
                                                                :placeholder="$t('Поиск')"
                                                                v-model="electionPartsSearch"
                                                                class="mb-2 autocomplete-search"
                                                                hide-details
                                                                dense
                                                                clearable
                                                                autofocus
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                                <template slot="selection" slot-scope="data">
                                                    <!-- HTML that describe how select should render selected items -->
                                                    <div class="v-select__selection v-select__selection--comma">
                                                        {{ getElectionPartName(data.item) }}
                                                    </div>
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    <!-- HTML that describe how select should render items when the select is open -->
                                                    <div class="v-list-item__content">
                                                        <div class="v-list-item__title">
                                                            {{ getElectionPartName(data.item) }}
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>

                            <template v-if="filterPanelState">
                                <v-row class="two-columns-field-in-one-box" no-gutters>                                    
                                    <v-col cols="12" sm="12" md="6">

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Список_на") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-menu 
                                                    v-model="dateMenuVisibleListOn"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="tmpExtendedFilter.listDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            hide-details
                                                            required
                                                            outlined
                                                            dense
                                                            class="datepick-input"
                                                            clearable
                                                            @click:clear="tmpExtendedFilter.listDate = null"
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker 
                                                        v-model="tmpExtendedFilter.listDate"
                                                        @input="dateMenuVisibleListOn = false"
                                                        color="teal"
                                                        :first-day-of-week="1"
                                                        :min="$moment().format('yyyy-MM-DD')"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Фамилия") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.surname"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Имя") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.name"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Отчество") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.patronymic"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Дата_рождения")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="more-inputs-in-row">
                                                    <span>{{ $t("c") }}</span>
                                                    
                                                    <v-menu 
                                                        v-model="dateMenuVisibleDateBirthFrom"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="tmpExtendedFilter.birthDateFrom | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                clearable
                                                                @click:clear="tmpExtendedFilter.birthDateFrom = null"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <div class="d-flex">
                                                            <v-date-picker 
                                                                v-model="tmpExtendedFilter.birthDateFrom"
                                                                @input="dateMenuVisibleDateBirthFrom = false"
                                                                color="teal"
                                                                :first-day-of-week="1"
                                                            >
                                                                <v-btn 
                                                                    color="teal" 
                                                                    @click="showMonthSelectorBirthFrom = true, showYearSelectorBirthFrom = false"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                >
                                                                    {{ $t("Выбрать_месяц") }}
                                                                </v-btn>
                                                                <v-spacer></v-spacer>
                                                                <v-btn 
                                                                    color="teal" 
                                                                    @click="showYearSelectorBirthFrom = true, showMonthSelectorBirthFrom = false"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                >
                                                                    {{ $t("Выбрать_год") }}
                                                                </v-btn>
                                                            </v-date-picker>

                                                            <v-card
                                                                v-if="showYearSelectorBirthFrom"
                                                                max-width="400px"
                                                                max-height="414px"
                                                                class="datepick-scrollable-content"
                                                                flat
                                                            >
                                                                <v-card-text>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <v-btn 
                                                                                text 
                                                                                small 
                                                                                color="teal" 
                                                                                @click="showYearSelectorBirthFrom = false"
                                                                            >
                                                                                <v-icon left>far fa-arrow-from-right</v-icon>
                                                                                {{ $t("Назад") }}
                                                                            </v-btn>    
                                                                        </v-col> 
                                                                        <v-col
                                                                            v-for="year in years"
                                                                            :key="year"
                                                                            cols="3"
                                                                            class="d-flex justify-center"
                                                                        >
                                                                            <v-btn
                                                                                text
                                                                                small
                                                                                rounded
                                                                                @click="selectYearBirthFrom(year)"
                                                                            >
                                                                                {{ year }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>

                                                            <v-card
                                                                v-else-if="showMonthSelectorBirthFrom"
                                                                max-width="400px"
                                                                max-height="414px"
                                                                class="datepick-scrollable-content"
                                                                flat
                                                            >
                                                                <v-card-text>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <v-btn 
                                                                                text 
                                                                                small 
                                                                                color="teal" 
                                                                                @click="showMonthSelectorBirthFrom = false"
                                                                            >
                                                                                <v-icon left>far fa-arrow-from-right</v-icon>
                                                                                {{ $t("Назад") }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col
                                                                            v-for="(month, index) in months"
                                                                            :key="index"
                                                                            cols="3"
                                                                            class="d-flex justify-center"
                                                                        >
                                                                            <v-btn
                                                                                text
                                                                                small
                                                                                rounded
                                                                                @click="selectMonthBirthFrom(index)"
                                                                            >
                                                                                {{ month }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>
                                                        </div>
                                                    </v-menu>

                                                    <span>{{ $t("по") }}</span>
                                                    
                                                    <v-menu 
                                                        v-model="dateMenuVisibleDateBirthBy"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="tmpExtendedFilter.birthDateTo | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                clearable
                                                                @click:clear="tmpExtendedFilter.birthDateTo = null"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <div class="d-flex">
                                                            <v-date-picker 
                                                                v-model="tmpExtendedFilter.birthDateTo"
                                                                @input="dateMenuVisibleDateBirthBy = false"
                                                                color="teal"
                                                                :first-day-of-week="1"
                                                            >
                                                                <v-btn 
                                                                    color="teal" 
                                                                    @click="showMonthSelectorBirthTo = true, showYearSelectorBirthTo = false"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                >
                                                                    {{ $t("Выбрать_месяц") }}
                                                                </v-btn>
                                                                <v-spacer></v-spacer>
                                                                <v-btn 
                                                                    color="teal" 
                                                                    @click="showYearSelectorBirthTo = true, showMonthSelectorBirthTo = false"
                                                                    small
                                                                    dark
                                                                    depressed
                                                                >
                                                                    {{ $t("Выбрать_год") }}
                                                                </v-btn>  
                                                            </v-date-picker>

                                                            <v-card
                                                                v-if="showYearSelectorBirthTo"
                                                                max-width="400px"
                                                                max-height="414px"
                                                                class="datepick-scrollable-content"
                                                                flat
                                                            >
                                                                <v-card-text>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <v-btn 
                                                                                text 
                                                                                small 
                                                                                color="teal" 
                                                                                @click="showYearSelectorBirthTo = false"
                                                                            >
                                                                                <v-icon left>far fa-arrow-from-right</v-icon>
                                                                                {{ $t("Назад") }}
                                                                            </v-btn>    
                                                                        </v-col> 
                                                                        <v-col
                                                                            v-for="year in years"
                                                                            :key="year"
                                                                            cols="3"
                                                                            class="d-flex justify-center"
                                                                        >
                                                                            <v-btn
                                                                                text
                                                                                small
                                                                                rounded
                                                                                @click="selectYearBirthTo(year)"
                                                                            >
                                                                                {{ year }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>

                                                            <v-card
                                                                v-else-if="showMonthSelectorBirthTo"
                                                                max-width="400px"
                                                                max-height="414px"
                                                                class="datepick-scrollable-content"
                                                                flat
                                                            >
                                                                <v-card-text>
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <v-btn 
                                                                                text 
                                                                                small 
                                                                                color="teal" 
                                                                                @click="showMonthSelectorBirthTo = false"
                                                                            >
                                                                                <v-icon left>far fa-arrow-from-right</v-icon>
                                                                                {{ $t("Назад") }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col
                                                                            v-for="(month, index) in months"
                                                                            :key="index"
                                                                            cols="3"
                                                                            class="d-flex justify-center"
                                                                        >
                                                                            <v-btn
                                                                                text
                                                                                small
                                                                                rounded
                                                                                @click="selectMonthBirthTo(index)"
                                                                            >
                                                                                {{ month }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                            </v-card>
                                                        </div>
                                                    </v-menu>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-col>

                                    <v-col  cols="12" sm="12" md="6">
                                        <!--пустая строка для отступа-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="12">
                                                <div class="onlyReadData"></div>    
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("ИИН") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.iin"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("№_документа")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <v-text-field
                                                    v-model="tmpExtendedFilter.docNumber"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>  
                                            </v-col>
                                        </v-row>

                                    </v-col>

                                </v-row>

                            </template>

                        </v-card-text>
                        <v-card-actions v-if="filterPanelState">
                            <v-spacer></v-spacer>

                            <v-btn
                                color="blue-grey"
                                text
                                depressed
                                dense
                                @click="clearExtendedFilter"
                            >
                                {{ $t("Сбросить_фильтр") }} 
                            </v-btn>

                            <v-btn
                                color="cyan"
                                text
                                depressed
                                dense
                                @click="setExtendedFilter"
                            >
                                {{ $t("Фильтровать") }}
                            </v-btn>

                        </v-card-actions>
                        <div class="extend-filter-btn-open" @click.stop="filterPanelState = !filterPanelState">
                            <v-icon class="mr-1">
                                {{ !filterPanelState ? 'fas fa-expand-alt' : 'fas fa-compress-alt' }}
                            </v-icon>
                            Фильтр
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>

        <!--Таблица для примера-->
        <v-data-table
            class="elevation-1 customization-list-table pagination-list-table sticky-table"
            :headers="headers"
            :options.sync="options"
            :page.sync="options.page"
            :items="entities"
            :server-items-length="total"
            :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
            @page-count="paginationPageCount = $event"
            @dblclick:row="(event, data) => $router.push({ name: 'Voter', params: { id: data.item.hash, temporary: true } })"
            :loading="loading"
        >

            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.detachment`]="{ item }">
                <v-icon
                    v-if="!item.detachment"
                    small
                    color="green"
                >
                    far fa-inbox-in
                </v-icon>
                <v-icon
                    v-else
                    small
                    color="red"
                >
                    far fa-inbox-out
                </v-icon>
            </template>

            <template v-slot:[`item.birthDate`]="{ item }">
                {{ $moment(item.birthDate).format('DD.MM.yyyy') }}
            </template>

            <template v-slot:[`item.toDate`]="{ item }">
                {{ $moment(item.toDate).format('DD.MM.yyyy') }}
            </template>

            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>
        </v-data-table>

        <ImportTemporaryVotersListDlg ref="ImportTemporaryVotersListDlgRef" />

    </div>
</template>

<script>
import store from '@/store';
import i18n from '@/i18n';
import sys from '@/services/system';
import { httpAPI, errorHandler } from "@/api/httpAPI";

import ImportTemporaryVotersListDlg from '@/components/saylau/dialogs/ImportTemporaryVotersListDlg.vue';

const getDefaultFilter = () => {
    return {
        surname: "",
        name: "",
        patronymic: "",
        birthDateFrom: null,
        birthDateTo: null,
        iin: "",
        docNumber: "",
        listDate: null
    }
};

export default {
    name: "Voters",
    components: {
        ImportTemporaryVotersListDlg
    },
    asyncComputed: {
        regions: {
            async get() {
                let regions = await store.dispatch('saylau/references/fetchRegions');
                if (regions.length == 1)
                    this.region = regions[0].id;
                if (this.regionsSearch)
                    return regions.map(x => x).filter(i => sys.checkSearch(i.name, this.regionsSearch));
                else
                    return regions.map(x => x);
            },
            default: [],
            watch: ['regionsSearch']
        },
        electionParts: {
            async get() {
                if (!this.region)
                    return [];

                let parts = (await httpAPI.get(`saylau/election-parts/in-region/${this.region}`))?.data ?? [];

                if (this.electionPartsSearch) {
                    return parts.map(x => x).filter(i => sys.checkSearch(this.locale === 'ru' ? i.nameRu : i.nameKz, this.electionPartsSearch));
                }
                else {
                    return parts.map(x => x);
                }
            },
            default: [],
            watch: ['region', 'electionPartsSearch']
        }
    },
    computed: {
        headers() {
            return [
                { text: i18n.t(""), value: 'detachment', width: '20px', sortable: false },
                { text: i18n.t("Участок_открепления"), value: 'electionPart', width: '150px', sortable: false },
                { text: i18n.t("Участок_прикрепления"), value: 'tmpElectionPart', width: '150px', sortable: false },
                { text: i18n.t("До"), value: 'toDate', width: '110px', sortable: false },
                { text: i18n.t("ИИН"), value: 'hash', width: '110px', sortable: false },
                { text: i18n.t("Фамилия_Имя_Отчество"), value: 'fullName' },
                { text: i18n.t("Дата_рождения"), value: 'birthDate', width: '110px', sortable: false },
                { text: i18n.t("№_документа"), value: 'documentNumber', width: '150px', sortable: false }
            ];
        },
        filterPanelState: {
            get: function() {
                return this.$store.getters['saylau/lists/tempVotersInPartList/getFilterPanelState'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/tempVotersInPartList/SET_FILTER_PANEL_STATE', v);
            }
        },
        options: {
            get: function() {
                return this.$store.getters['saylau/lists/tempVotersInPartList/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/tempVotersInPartList/SET_TABLE_OPTION', newValue);
            }
        },
        region: {
            get: function() {
                return this.$store.getters['saylau/lists/tempVotersInPartList/getRegion'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/tempVotersInPartList/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/tempVotersInPartList/SET_REGION', v);
            }
        },
        electionPart: {
            get: function() {
                return this.$store.getters['saylau/lists/tempVotersInPartList/getElectionPart'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/tempVotersInPartList/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/tempVotersInPartList/SET_ELECTION_PART', v);
            }
        },
        extendedFilter() {
            return this.$store.getters['saylau/lists/tempVotersInPartList/getExtendedFilter'];
        },
        filter () {
            return {
                ...this.options,
                region: this.region,
                electionPart: this.electionPart,
                extendedFilter: this.extendedFilter
            }
        },
        locale() {
            return this.$i18n.locale;
        }
    },
    data() {
        return {
            dateMenuVisibleListOn: false,
            dateMenuVisibleDateBirthFrom: false,
            dateMenuVisibleDateBirthBy: false,

            showYearSelectorBirthFrom: false,
            showMonthSelectorBirthFrom: false,
            showYearSelectorBirthTo: false,
            showMonthSelectorBirthTo: false,
            years: Array.from({ length: 125 }, (_, i) => new Date().getFullYear() - i),
            months: [
                i18n.t('Январь'), i18n.t('Февраль'), i18n.t('Март'), i18n.t('Апрель'), i18n.t('Май'), i18n.t('Июнь'), 
                i18n.t('Июль'), i18n.t('Август'), i18n.t('Сентябрь'), i18n.t('Октябрь'), i18n.t('Ноябрь'), i18n.t('Декабрь')
            ],

            electionPartsSearch: null,
            regionsSearch: null,

            paginationPageCount: 0,

            total: 0,
            entities: [],
            loading: false,

            tmpExtendedFilter: getDefaultFilter()
        }
    },
    methods: {
        clearExtendedFilter() {
            this.tmpExtendedFilter = getDefaultFilter();
            this.$store.commit('saylau/lists/tempVotersInPartList/SET_EXTENDED_FILTER', null);
        },
        setExtendedFilter() {
            this.$store.commit('saylau/lists/tempVotersInPartList/SET_EXTENDED_FILTER', JSON.parse(JSON.stringify(this.tmpExtendedFilter)));
        },
        loadVoters() {
            this.entities = [];
            this.total = 0;
            this.loading = true;

            if (this.extendedFilter)
                this.tmpExtendedFilter = JSON.parse(JSON.stringify(this.extendedFilter));

            httpAPI
                .get(`saylau/voters/temp?filter=${this.getTableFilter()}`)
                .then(response =>
                {
                    this.entities = response.data.items;
                    this.total = response.data.count;
                })
                .finally(() => this.loading = false);
        },
        getTableFilter() {
            let { region, electionPart, extendedFilter, page, itemsPerPage, sortBy, sortDesc } = this.filter;

            let orderBy = sortBy.length <= 0 ? null : sortBy[0];
            let descending = sortDesc.length <= 0 ? null : sortDesc[0];            

            let filter = {
                region,
                electionPart,
                page,
                itemsPerPage,
                orderBy,
                descending
            };

            if (extendedFilter != null)
                filter['extendedFilter'] = extendedFilter;


            return JSON.stringify(filter);
        },
        getElectionPartName(item){
            if(item == null)
                return ''; 
            if (this.locale === 'ru')
                return item.nameRu;
            else 
                return item.nameKz;
        },
        selectYearBirthFrom(year) {
            const date = new Date(this.tmpExtendedFilter.birthDateFrom || new Date());
            date.setFullYear(year);
            this.tmpExtendedFilter.birthDateFrom = date.toISOString().substring(0, 10);
            this.showYearSelectorBirthFrom = false;
        },
        selectMonthBirthFrom(monthIndex) {
            const date = new Date(this.tmpExtendedFilter.birthDateFrom || new Date());
            date.setMonth(monthIndex);
            this.tmpExtendedFilter.birthDateFrom = date.toISOString().substring(0, 10);
            this.showMonthSelectorBirthFrom = false;
        },
        selectYearBirthTo(year) {
            const date = new Date(this.tmpExtendedFilter.birthDateTo || new Date());
            date.setFullYear(year);
            this.tmpExtendedFilter.birthDateTo = date.toISOString().substring(0, 10);
            this.showYearSelectorBirthTo = false;
        },
        selectMonthBirthTo(monthIndex) {
            const date = new Date(this.tmpExtendedFilter.birthDateTo || new Date());
            date.setMonth(monthIndex);
            this.tmpExtendedFilter.birthDateTo = date.toISOString().substring(0, 10);
            this.showMonthSelectorBirthTo = false;
        },
        async ImportList(){
            let { file, toDate } = await this.$refs.ImportTemporaryVotersListDlgRef.open();

            let formData = new FormData();
            formData.append('file', file);
            
            let onResolve = (response) => {
                this.$notify.success(response.data);
            };

            let onReject = (error) => {
                errorHandler(error);
            };

            let request = httpAPI.post(`saylau/temporary-voters/import?toDate=${toDate}`, formData, { headers: {"Content-Type": "multipart/form-data"}  });
            this.$notify.async(request, onResolve, onReject, this.$t("Подготовка_данных"), { icons: { enabled: true } });
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
    watch: {
        region: function () {            
            this.total = 0;
            this.entities = [];
            this.electionPart = null;
        },
        electionPart: function(newElectionPart) {
            if (newElectionPart == null) {
                this.total = 0;
                this.entities = [];
            }
        },
        filter: {
            handler () {
                if (this.region)
                    this.loadVoters();
            },
            deep: true,
        }
    },
    async beforeRouteEnter(to, from, next) {
        let filter = store.getters['saylau/lists/tempVotersInPartList/getExtendedFilter'];

        next(vm => {
            if (filter)
                vm.tmpExtendedFilter = JSON.parse(JSON.stringify(filter));
        });
    },
    async beforeRouteUpdate(to, from, next) {        
        if (this.extendedFilter)
            this.tmpExtendedFilter = JSON.parse(JSON.stringify(this.extendedFilter));

        next();
    }
}
</script>